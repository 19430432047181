export default [
    {
        header: 'Secciones',
    },
    {
        title: 'Dashboard',
        route: 'Dashboard',
        icon: 'HomeIcon',
    },
    {
        title: 'Clientes',
        route: 'Clientes',
        icon: 'UsersIcon',
    },
    {
        title: 'Visitas',
        route: 'Visitas',
        icon: 'CalendarIcon',
    },
    {
        header: 'Configuración',
    },
    {
        title: 'Usuarios',
        route: 'Usuarios',
        icon: 'UserIcon',
    },

]
